<template>
  <div class="topbar-item">
    <div class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2" id="kt_quick_user_toggle">
      <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
        {{ $t('Hi') }},
      </span>
      <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
        {{ currentUserPersonalInfo.name }}
      </span>
      <span class="symbol symbol-35 symbol-light-success">
        <img v-if="false" alt="Pic" :src="currentUserPersonalInfo.photo"/>
        <span v-if="true" class="symbol-label font-size-h5 font-weight-bold">
          {{ currentUserPersonalInfo.name.charAt(0).toUpperCase() }}
        </span>
      </span>
    </div>

    <div id="kt_quick_user" ref="kt_quick_user" class="offcanvas offcanvas-right p-10">
      <!--begin::Header-->
      <div class="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 class="font-weight-bold m-0">
          {{ $t('User_Profile') }}
        </h3>
        <a href="#" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_user_close">
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar class="offcanvas-content pr-5 mr-n5 scroll" style="max-height: 90vh; position: relative;">
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <img class="symbol-label" :src="currentUserPersonalInfo.photo" alt=""/>
            <i class="symbol-badge bg-success"></i>
          </div>
          <div class="d-flex flex-column">
            <span class="font-weight-bold font-size-h5 text-green">
              {{ getFullName }}
            </span>
            <div class="text-muted mt-1">{{ currentUserPersonalInfo.job }}</div>
            <div class="navi mt-2">
              <span class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg src="/media/svg/icons/Communication/Mail-notification.svg"/>
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                  <span class="navi-text text-muted text-hover-primary">
                    {{ currentUserPersonalInfo.email }}
                  </span>
                </span>
              </span>
            </div>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              {{ $t('Sign_out') }}
            </button>
          </div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0">
          <!--begin::Item-->
          <template v-if="is_main">
            <a @click="closeOffcanvas" class="navi-item">
              <div class="navi-link">
                <div class="symbol symbol-40 bg-light mr-3">
                  <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <!--begin::Svg Icon-->
                    <inline-svg src="/media/svg/icons/General/Notification2.svg"/>
                    <!--end::Svg Icon-->
                  </span>
                  </div>
                </div>
                <div class="navi-text">
                  <router-link to="/company-configuration">
                    <div class="font-weight-bold">{{ $t('header.my_profile') }}</div>
                  </router-link>
                  <div class="text-muted">
                    {{ $t('header.account_settings_and_more') }}
                  </div>
                </div>
              </div>
            </a>
          </template>
          <a @click="closeOffcanvas" class="navi-item">
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <!--begin::Svg Icon-->
                    <inline-svg src="/media/svg/icons/General/Notification2.svg"/>
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <router-link to="/user-settings">
                  <div class="font-weight-bold">{{ $t('header.change_email_password') }}</div>
                </router-link>
              </div>
            </div>
          </a>
          <!--end:Item-->

          <template v-if="is_main">
            <a @click="closeOffcanvas" class="navi-item" v-if="is_renewal_plan">
              <div class="navi-link">
                <div class="symbol symbol-40 bg-light mr-3">
                  <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <!--begin::Svg Icon-->
                    <inline-svg src="/media/svg/icons/General/Notification2.svg"/>
                    <!--end::Svg Icon-->
                  </span>
                  </div>
                </div>
                <div class="navi-text">
                  <a href="javascript:;" @click="redirectRenewalSubscription()">
                    <div class="font-weight-bold">{{ $t('header.renewal_of_plan_subscription') }}</div>
                  </a>
                </div>
              </div>
            </a>
            <!--end:Item-->
            <a @click="closeOffcanvas" class="navi-item">
              <div class="navi-link">
                <div class="symbol symbol-40 bg-light mr-3">
                  <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <!--begin::Svg Icon-->
                    <inline-svg src="/media/svg/icons/General/Notification2.svg"/>
                    <!--end::Svg Icon-->
                  </span>
                  </div>
                </div>
                <div class="navi-text">
                  <a href="javascript:;" @click="redirectPlanUpgrade">
                    <div class="font-weight-bold">{{ $t('header.plan_upgrade') }}</div>
                  </a>
                </div>
              </div>
            </a>
            <!--end:Item-->

          </template>


        </div>
        <!--end::Nav-->

      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import {mapGetters} from "vuex";
import {LOGOUT} from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import {UPDATE_PERSONAL_INFO} from "@/core/services/store/profile.module";
import i18nService from "@/core/services/i18n.service";

export default {
  name: "KTQuickUser",
  data() {
    return {
      lang: i18nService.getCurrentLanguage(),
      list: []
    };
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
    this.$store.dispatch(UPDATE_PERSONAL_INFO);
  },
  methods: {
    onLogout() {
      this.$store
          .dispatch(LOGOUT)
          .then(() => this.$router.push({name: "login"}));
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
    redirectRenewalSubscription() {
      let _url = process.env.VUE_APP_SERVER_WEB + '/' + this.lang + '/subscription/' + this.currentUserPersonalInfo.plan_id + '?subscription_for=renewal&user=' + this.currentUserPersonalInfo.uuid
      window.open(_url, '_blank');
    },
    redirectPlanUpgrade() {
      let _url = process.env.VUE_APP_SERVER_WEB + '/' + this.lang + '/plans?subscription_for=upgrade&user=' + this.currentUserPersonalInfo.uuid
      window.open(_url, '_blank');
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),

    getFullName() {
      return (
          this.currentUserPersonalInfo.name + " " + this.currentUserPersonalInfo.surname
      );
    },
    getCurrentPlan() {
      return (this.currentUserPersonalInfo.plan_id);
    },
    is_renewal_plan() {
      return (this.currentUserPersonalInfo.is_renewal_plan);
    },
    is_main() {
      return (this.currentUserPersonalInfo.is_main);
    }
  }
};
</script>
