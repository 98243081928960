<template>
<div>
<!--  your_subscription_will_expire-->
  <b-alert :show="is_renewal_plan" variant="warning">
    <p>{{ $t('renewal_plan.your_subscription_will_expire', {date: end_date}) }}</p>
    <div>
      <button class="btn btn-outline-white btn-sm mr-2 my-2" @click="redirectRenewalSubscription">
        {{$t('renewal_plan.renewal_subscription')}}
      </button>
    </div>
  </b-alert>
</div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: "SubscriptionRenewalPlanMessage",
  data(){
    return{
      is_show: false,
      showDismissibleAlert: true,
      // display_extension_days:{
      //   end_date: null,
      //   extension_days: null,
      // }
    }
  },
  computed: {
    // ...mapState({display_extension_days: state => state.profile.user_personal_info.display_extension_days}),
    ...mapGetters(["getCompanyData"]),
    end_date(){
      if (this.getCompanyData && this.getCompanyData.display_extension_days){
        return this.getCompanyData.display_extension_days.end_date;
      }else {
        return '';
      }
    },
    getCurrentPlan () {
      return (this.getCompanyData.plan_id);
    },
    getUuid () {
      return (this.getCompanyData.account_uuid);
    },
    is_renewal_plan () {
      return (this.getCompanyData.is_renewal_plan);
    }
  },
 methods:{
   redirectRenewalSubscription() {
     let _url = process.env.VUE_APP_SERVER_WEB+'/'+this.lang+'/subscription/'+this.getCurrentPlan+'?subscription_for=renewal&user='+this.getUuid
     window.open(_url, '_blank');
   },
 },
  mounted() {
  },
  created() {

  },
}
</script>

<style scoped>

</style>