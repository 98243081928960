import { render, staticRenderFns } from "./ExpiredDaysPlanMessage.vue?vue&type=template&id=12065dc4&scoped=true&"
import script from "./ExpiredDaysPlanMessage.vue?vue&type=script&lang=js&"
export * from "./ExpiredDaysPlanMessage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12065dc4",
  null
  
)

export default component.exports