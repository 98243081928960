<template>
<div>
  <b-alert :show="is_show" variant="danger">
    <p>{{ $t('you_need_to_create_account_tree_to_use_this_module') }}</p>
    <router-link to="/accounting/financial-years/create" class=" btn btn-outline-white btn-sm"> {{ $t('financial_years.new_financial_year') }} </router-link>
    <span class="mr-1 ml-1"> </span>
    <a href="javascript:;" @click="showModal" class=" btn btn-outline-white btn-sm"> {{$t('accounting_tree')}} </a>
  </b-alert>
  <b-modal ref="modal" hide-footer :title="$t('accounting_tree')">
    <div>
      <div class="scroll-h-470">
        <v-treeview
            :items="accounting_tree"
            return-object
            selected-color="warning"
            :selection-type="selectionType"
            hoverable
            expand-icon="mdi-chevron-down"
            on-icon="mdi-bookmark"
            off-icon="mdi-bookmark-outline"
            indeterminate-icon="mdi-bookmark-minus"
        >
          <template v-slot:prepend="{ item, open }">
            <v-icon v-if="!item.file">
              {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
            </v-icon>
            <v-icon v-else>
              {{ files[item.file] }}
            </v-icon>
          </template>
        </v-treeview>
      </div>

      <b-button class="mt-2 mr-1" variant="secondary" @click="hideModal">{{$t('close')}}</b-button>
    </div>
  </b-modal>
</div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "AccountingTreeMessage",
  data(){
    return{
      mainRouteDependency: 'base/dependency',
      current_route: this.$route.fullPath? this.$route.fullPath : null,
      is_show: false,
      financial_year: null,
      accounting_tree: [],
      selectionType: 'independent',
    }
  },
  watch:{
    "$route": function (val){
      this.current_route = val.fullPath;
      this.initialFn();
    },
  },
  methods:{
    initialFn(){
        let promise  = this.getAvailableFinancialYear();
        Promise.all([promise]).then(()=>{
          this.useRouteToShowMessage();
        });
    },
    useRouteToShowMessage(){
      let _current_route = this.current_route ? this.current_route.split('/'): [];
      if (_current_route[1] && _current_route[1] == 'accounting' && !this.financial_year)
        this.is_show = true;
      else
        this.is_show = false;
    },
    async getAvailableFinancialYear() {
      await ApiService.get(this.mainRouteDependency + "/available-financial-year").then((response) => {
          this.financial_year = response.data.data.id ? response.data.data.id : null;
      });
    },
    async getStaticAccountingTree() {
      await ApiService.get(this.mainRouteDependency + "/static-accounting-tree").then((response) => {
          this.accounting_tree = response.data.data;
      });
    },

    showModal() {
      this.$refs['modal'].show();
    },
    hideModal() {
      this.$refs['modal'].hide()
    },

  },
  mounted() {

  },
  created() {
    this.getStaticAccountingTree();
    this.initialFn();

  },
}
</script>

<style scoped>

</style>