<template>
<div>
<!--  your_subscription_will_expire-->
  <b-alert :show="isShow" variant="danger">
    <p class="mb-0">{{ $t('expired_days_plan.your_subscription_expired') }}</p>
  </b-alert>
</div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: "ExtendDaysPlanMessage",
  data(){
    return{
      mainRoute: 'apply_extension_days',
      mainRouteDependency: 'base/dependency',
      is_show: false,
    }
  },
  computed: {
    // ...mapState({is_show_expired_company_account: state => state.company_data.is_show_expired_company_account}),
    ...mapGetters(["getCompanyData"]),
    isShow(){
      return this.getCompanyData.is_show_expired_company_account ? true : false;
    }
  },
  // methods:{
  //   save(status){
  //     ApiService.post(this.mainRoute, {
  //       is_use_extension_days: status,
  //     }).then((response) => {
  //       this.$successAlert(response.data.message);
  //       this.$router.go(this.$router.currentRoute);
  //     }).catch((error) => {
  //       Vue.prototype.$postStatus = true;
  //       this.$errorAlert(error);
  //     });
  //   }
  // },
  mounted() {
  },
  created() {

  },
}
</script>

<style scoped>

</style>