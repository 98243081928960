<template>
  <!-- begin:: Header -->
  <div
    id="kt_header"
    ref="kt_header"
    class="header"
    v-bind:class="headerClasses"
  >
    <div
      class="container-fluid d-flex align-items-center justify-content-between"
    >
      <!-- begin:: Header Menu -->
      <div
        class="header-menu-wrapper header-menu-wrapper-left"
        ref="kt_header_menu_wrapper"
      >
        <div
          v-if="headerMenuEnabled"
          id="kt_header_menu"
          ref="kt_header_menu"
          class="header-menu header-menu-mobile"
          v-bind:class="headerMenuClasses"
        >
          <!-- example static menu here -->
            <!-- <div data-v-4af3075b="" class="brand brandWhite flex-column-auto"><div data-v-4af3075b="" class="text-green calender-data-date"><i data-v-4af3075b="" class="fa fa-calendar text-green"></i> <span data-v-4af3075b="" class="pl-1 pr-1">2023-12-25</span></div><div data-v-4af3075b="" class="text-green calender-data-time"><i data-v-4af3075b="" class="fa fa-clock text-green"></i> <span data-v-4af3075b="" class="pl-1 pr-1">12:55:28</span></div></div> -->

            <div class="brand brandWhite flex-column-auto">
                <div class="text-green calender-data-date pl-4"><i class="fa fa-calendar text-green"></i> <span class="pl-1 pr-1">{{ date_new }}</span></div>
                <div class="text-green calender-data-time"><i class="fa fa-clock text-green"></i> <span class="pl-1 pr-1">{{ time_new }}</span></div>
            </div>
            
        </div>
      </div>

      

      <!-- end:: Header Menu -->
      <KTTopbar></KTTopbar>
    </div>
  </div>
  <!-- end:: Header -->
</template>

<script>
import { mapGetters } from "vuex";
import KTTopbar from "@/view/layout/header/Topbar.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";

export default {
  name: "KTHeader",
  data() {
      return {
          date_new: null,
          time_new: null,
      }
  },
  components: {
    KTTopbar,

  },
  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");
    this.date_new = this.$moment().format('YYYY-MM-DD');
    let that = this;
    setInterval(() => {
        that.time_new = that.$moment().format('hh:mm:ss');
    }, 1000);
    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_menu_wrapper"]
    );

    const headerRef = this.$refs["kt_header"];

    headerRef.querySelectorAll("a[class='menu-link']").forEach(item => {
      item.addEventListener("click", () => {
        KTLayoutHeaderMenu.getOffcanvas().hide();
      });
    });
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    }
  }
};
</script>

<style lang="scss" scoped>
    .brandWhite{
        background-color: #ffffff !important;
    }
      .brand {
          background-color: #ffffff !important;
          padding-right: 0.4rem;
      }
    .text-green  {
        color: #0C6F58;
    }
</style>