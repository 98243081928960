import { render, staticRenderFns } from "./AccountingTreeMessage.vue?vue&type=template&id=8a67f8aa&scoped=true&"
import script from "./AccountingTreeMessage.vue?vue&type=script&lang=js&"
export * from "./AccountingTreeMessage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a67f8aa",
  null
  
)

export default component.exports