<template>
<div>
<!--  your_subscription_will_expire-->
  <b-alert :show="isShow" variant="warning">
    <p>{{ $t('extend_days_plan.your_subscription_will_expire', {date: end_date, days: extension_days}) }}</p>
    <div>
      <button class="btn btn-outline-white btn-sm mr-2 my-2" @click="save(1)">
        {{$t('extend_days_plan.get_extended_days')}}
      </button>
      <button class="btn btn-outline-white btn-sm mr-2 my-2" @click="save(2)">
        {{$t('extend_days_plan.ignore')}}
      </button>
    </div>
  </b-alert>
</div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import {mapGetters, mapState} from "vuex";
import Vue from "vue";

export default {
  name: "ExtendDaysPlanMessage",
  data(){
    return{
      mainRoute: 'apply_extension_days',
      mainRouteDependency: 'base/dependency',
      is_show: false,
      showDismissibleAlert: true,
      // display_extension_days:{
      //   end_date: null,
      //   extension_days: null,
      // }
    }
  },
  computed: {
    // ...mapState({display_extension_days: state => state.company_data.display_extension_days}),
    ...mapGetters(["getCompanyData"]),
    end_date(){
      if (this.getCompanyData && this.getCompanyData.display_extension_days){
        return this.getCompanyData.display_extension_days.end_date;
      }else {
        return null;
      }
    },
    extension_days(){
      if (this.getCompanyData && this.getCompanyData.display_extension_days){
        return this.getCompanyData.display_extension_days.extension_days;
      }else {
        return null;
      }
    },
    is_use_extension_days(){
      if (this.getCompanyData && this.getCompanyData.display_extension_days){
        if (this.getCompanyData.display_extension_days.is_use_extension_days == 0){
          this.is_show = true;
        }
        return this.getCompanyData.display_extension_days.is_use_extension_days ? true : false;
      }else {
        // this.is_show = true;
        return false;
      }
    },
    isShow(){
      if (this.is_show && !this.is_use_extension_days && this.end_date && this.extension_days){
        return true;
      }else {
        return false;
      }
    }
  },
  methods:{
    save(status){
      ApiService.post(this.mainRoute, {
        is_use_extension_days: status,
      }).then((response) => {
        this.$successAlert(response.data.message);
        this.$router.go(this.$router.currentRoute);
      }).catch((error) => {
        Vue.prototype.$postStatus = true;
        this.$errorAlert(error);
      });
    }
  },
  mounted() {
  },
  created() {

  },
}
</script>

<style scoped>

</style>