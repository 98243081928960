<template>
<div v-if="company.is_setting_message == true || company.is_setting_message == null">
<!--  <b-alert :show="is_show && showDismissibleAlert" variant="warning" dismissible @dismissed="showDismissibleAlert=false">-->
  <b-alert :show="is_show" variant="0C6F58" dismissible fade>
    <p>{{ $t('you_must_adjust_the_system_settings_before_starting_to_use_it') }}</p>
    <div v-if="flags_list">
      <a v-if="flags_list.employees <= 0 && $can('employees.list')" href="/employees/employees" target="_blank" class="btn btn-outline-white btn-sm mr-2 my-2">
        {{$t('settings_initial.employees')}}
      </a>
      <a v-if="flags_list.suppliers <= 0 && $can('suppliers.list')" href="/purchases/suppliers" target="_blank" class="btn btn-outline-white btn-sm mr-2 my-2">
        {{$t('settings_initial.suppliers')}}
      </a>
      <a v-if="flags_list.workstations <= 0  && $can('workstation.list')" href="/manufactories/workstation" target="_blank" class="btn btn-outline-white btn-sm mr-2 my-2">
        {{$t('settings_initial.workstations')}}
      </a>

      <a v-if="flags_list.taxes <= 0 && $can('taxes.list')" href="/settings/taxes" target="_blank" class="btn btn-outline-white btn-sm mr-2 my-2">
        {{$t('settings_initial.taxes')}}
      </a>
      <a v-if="flags_list.units <= 0 && $can('units.list')" href="/settings/units" target="_blank" class="btn btn-outline-white btn-sm mr-2 my-2">
        {{$t('settings_initial.units')}}
      </a>
      <a v-if="flags_list.brands <= 0 && $can('brands.list')" href="/settings/brands" target="_blank" class="btn btn-outline-white btn-sm mr-2 my-2">
        {{$t('settings_initial.brands')}}
      </a>
      <a v-if="flags_list.branches <= 0 && $can('branches.list')" href="/settings/branches" target="_blank" class="btn btn-outline-white btn-sm mr-2 my-2">
        {{$t('settings_initial.branches')}}
      </a>
      <a v-if="flags_list.countries <= 0 && $can('countries.list')" href="/settings/countries" target="_blank" class="btn btn-outline-white btn-sm mr-2 my-2">
        {{$t('settings_initial.countries')}}
      </a>
      <a v-if="flags_list.currencies <= 0 && $can('currencies.list')" href="/settings/currencies" target="_blank" class="btn btn-outline-white btn-sm mr-2 my-2">
        {{$t('settings_initial.currencies')}}
      </a>
      <a v-if="flags_list.categories <= 0 && $can('categories.list')" href="/settings/categories" target="_blank" class="btn btn-outline-white btn-sm mr-2 my-2">
        {{$t('settings_initial.categories')}}
      </a>
      <a v-if="flags_list.inventories <= 0 && $can('inventories.list')" href="/settings/inventories" target="_blank" class="btn btn-outline-white btn-sm mr-2 my-2">
        {{$t('settings_initial.inventories')}}
      </a>
      <!-- v-if="$can('sales_invoices.update')" -->
      <a v-if="flags_list.manufacturers <= 0 && $can('manufacturers.list')" href="/settings/manufacturers" target="_blank" class="btn btn-outline-white btn-sm mr-2 my-2">
        {{$t('settings_initial.manufacturers')}}
      </a>


    </div>
  </b-alert>
</div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import {mapGetters} from "vuex";

export default {
  name: "SettingsInitialDataMessage",
  data(){
    return{
      mainRouteDependency: 'base/dependency',
      mainRoute: 'settings/company',
      current_route: this.$route.fullPath? this.$route.fullPath : null,
      is_show: false,
      flags_list: null,
      selectionType: 'independent',
      showDismissibleAlert: true,
      // company: [],
    }
  },
  watch:{
    "$route": function (val){
      // this.current_route = val.fullPath;
      this.initialFn();
    },
  },
  computed: {
    ...mapGetters({company: "getCompanyData"}),
  },
  methods:{

    // getCompany() {
      // ApiService.get(this.mainRoute + '/getCompany').then((response) => {
      // });
    // },

    initialFn(){
        let promise  = this.getSettingsInitialCRUD();
        Promise.all([promise]).then(()=>{
          this.useRouteToShowMessage();
        });
    },
    useRouteToShowMessage(){
      // let _current_route = this.current_route ? this.current_route.split('/'): [];
      // if (_current_route[1] && _current_route[1] == 'accounting' && !this.flags_list)
      if (this.flags_list){
        let _keys = Object.keys(this.flags_list);
        // this.is_show = false;
        _keys.forEach((row)=>{
          if (this.flags_list[row] <= 0 && this.$can(row+".list")){
            this.is_show = true;
          }
        });
      } else
        this.is_show = false;
    },
    async getSettingsInitialCRUD() {
      await ApiService.get(this.mainRouteDependency + "/check_data_exist").then((response) => {
          this.flags_list = response.data.data;
      });
    },

    // hiddenAlertFinally(){
    //   localStorage.setItem("show_alert_settings", 0);
    // }
  },
  mounted() {
    // this.getCompany();
  },
  created() {
    this.initialFn();
  },
}
</script>

<style scoped>

</style>