<template>
  <ul class="menu-nav p-0">
    <li class="menu-item menu-item-submenu">
      <div class="menu-link">
        <input v-model="menu_filter" :placeholder="$t('search')" class="form-control" type="text" @input="menuFilter">
      </div>
    </li>
    <router-link v-slot="{ href, navigate, isActive, isExactActive }" :to="'/dashboard'">
      <li
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
          aria-haspopup="true"
          class="menu-item"
          data-menu-toggle="hover">
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-dashboard"></i>
          <span class="menu-text">{{ $t('MENU.DASHBOARD') }}</span>
        </a>
      </li>
    </router-link>

    <li v-for="(menu, index_1) in menu_links" v-if="$canAny(menu.permission) && menu.active != '/dashboard'"
        :key="'menu_1'+index_1" aria-haspopup="true" class="menu-item menu-item-submenu"
        data-menu-toggle="hover"
        v-bind:class="{ 'menu-item-open': hasActiveChildren(menu.active) || menu_filter }">

      <a class="menu-link menu-toggle" href="javascript:;">
        <span class="svg-icon menu-icon">
          <i :class="menu.icon"></i>
        </span>
        <span class="menu-text">{{ menu.name }}</span>
        <i class="menu-arrow"></i>
      </a>

      <div class="menu-submenu" kt-hidden-height="80">
        <i class="menu-arrow"></i>
        <ul class="menu-subnav">
          <template v-for="(menu_2, index_2) in menu.sub_links">
            <template v-if="menu_2.sub_links">


              <li v-if="$canAny(menu_2.permission) && menu_2.active != '/dashboard'" aria-haspopup="true"
                  class="menu-item menu-item-submenu"
                  data-menu-toggle="hover"
                  v-bind:class="{ 'menu-item-open': hasActiveChildrenInArray(menu_2.active_link) || menu_filter }">

                <a class="menu-link menu-toggle" href="javascript:;">
                  <span class="svg-icon menu-icon">
                    <i :class="menu_2.icon"></i>
                  </span>
                  <span class="menu-text">{{ menu_2.name }}</span>
                  <i class="menu-arrow"></i>
                </a>

                <div class="menu-submenu" kt-hidden-height="80">
                  <i class="menu-arrow"></i>
                  <ul class="menu-subnav">

                    <template v-for="(menu_4, index_4) in menu_2.sub_links">
                      <router-link v-if="$can(menu_4.permission)" :key="'menu_4'+index_4" v-slot="{ href, navigate, isActive, isExactActive }"
                                   :to="menu_4.link">
                        <li :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']" aria-haspopup="true" class="menu-item"
                            data-menu-toggle="hover">
                          <a :href="href" class="menu-link" @click="navigate">
                            <i :class="menu_4.icon"></i>
                            <span class="menu-text">{{ menu_4.name }}</span>
                          </a>
                        </li>
                      </router-link>
                    </template>


                  </ul>
                </div>
              </li>


            </template>
            <template v-else>
              <router-link v-if="$can(menu_2.permission)" :key="'menu_2'+index_2" v-slot="{ href, navigate, isActive, isExactActive }"
                           :to="menu_2.link">
                <li :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']" aria-haspopup="true" class="menu-item"
                    data-menu-toggle="hover">
                  <a :href="href" class="menu-link" @click="navigate">
                    <i :class="menu_2.icon"></i>
                    <span class="menu-text">{{ menu_2.name }}</span>
                  </a>
                </li>
              </router-link>
            </template>
          </template>


        </ul>
      </div>
    </li>

  </ul>
</template>

<script>
export default {
  name: "KTMenu",
  data() {
    return {
      menu_filter: null,
      menu_links: [],
    }
  },
  // computed: {
  //     user_personal_info() {
  //         return this.$store.state.profile.user_personal_info;
  //     },
  //     getFullName() {
  //         return (
  //             this.user_personal_info.name + " " + this.user_personal_info.surname
  //         );
  //     }
  // },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    hasActiveChildrenInArray(list) {
      return list.indexOf(this.$route["path"]) !== -1;
    },
    getLinks() {
      return [
        /***********************DASHBOARD****************************/
        // {
        //   name: this.$t('MENU.DASHBOARD'), link: '/dashboard',  icon: 'menu-icon flaticon-dashboard', active:'/dashboard', permission: [],
        // },
        /*********************** Items Module****************************/
        {
          name: this.$t('MENU.Items_management'),
          link: null,
          icon: 'menu-icon la la-cogs',
          active: '/items',
          permission: ['items.list', 'price-list.list', 'inventory-transactions.list', 'inventory-monitoring.list', 'item_open_stock.list', 'inventory_checking.list', 'items.export_import_items', 'bundle_and_device.list', 'bundle_divide.list', 'items.inventory_statistics', 'collected_items.list', 'composite_items.list', 'item_table_setting.update', 'item_configuration.update', 'price_list_laundry_services.list', 'aspect.list'],
          sub_links: [
            {
              name: this.$t('MENU.Items'),
              link: '/items/items',
              icon: 'menu-icon flaticon-open-box',
              permission: 'items.list'
            },

            {
              name: this.$t('MENU.composite_items'), icon: 'menu-icon flaticon-interface-1',
              active_link: ['/items/composite-items', '/items/bundle-and-device', '/items/bundle-divide'],
              permission: ['composite_items.list', 'bundle_and_device.list', 'divide_bundle.list'],
              sub_links: [
                {
                  name: this.$t('MENU.composite_items'),
                  link: '/items/composite-items',
                  icon: 'menu-icon flaticon-interface-1',
                  permission: 'composite_items.list'
                },
                {
                  name: this.$t('MENU.bundle_and_device'),
                  link: '/items/bundle-and-device',
                  icon: 'menu-icon flaticon-open-box',
                  permission: 'bundle_and_device.list'
                },
                {
                  name: this.$t('MENU.bundle_divide'),
                  link: '/items/bundle-divide',
                  icon: 'menu-icon flaticon-open-box',
                  permission: 'divide_bundle.list'
                },
              ]
            },

            {
              name: this.$t('MENU.collected_items'), icon: 'menu-icon flaticon-bag',
              active_link: ['/items/collected-items', '/items/aspects'],
              permission: ['collected_items.list', 'aspect.list'],
              sub_links: [
                {
                  name: this.$t('MENU.collected_items'),
                  link: '/items/collected-items',
                  icon: 'menu-icon flaticon-bag',
                  permission: 'collected_items.list'
                },
                {
                  name: this.$t('MENU.aspects'),
                  link: '/items/aspects',
                  icon: 'menu-icon flaticon-open-box',
                  permission: 'aspect.list'
                },
              ]
            },

            {
              name: this.$t('MENU.Prices_lists'),
              link: '/items/price-lists',
              icon: 'menu-icon flaticon-file-1',
              permission: 'price-list.list'
            },
            {
              name: this.$t('MENU.price_lists_laundry_services'),
              link: '/items/price-lists-laundry-services',
              icon: 'menu-icon flaticon-file-1',
              permission: 'price_list_laundry_services.list'
            },

            {
              name: this.$t('MENU.inventories_management'), icon: 'menu-icon flaticon-bag',
              active_link: ['/items/open-stock-items', '/items/inventory-transactions', '/items/inventory-monitoring', '/items/inventory-checking', '/items/inventory_statistics', '/items/inventory_statistics_tracking',],
              permission: ['item_open_stock.list', 'inventory-transactions.list', 'inventory-monitoring.list', 'inventory_checking.list', 'items.inventory_statistics',],
              sub_links: [
                {
                  name: this.$t('MENU.open_stock_items'),
                  link: '/items/open-stock-items',
                  icon: 'menu-icon la la-warehouse',
                  permission: 'item_open_stock.list'
                },
                {
                  name: this.$t('MENU.Inventory_transactions'),
                  link: '/items/inventory-transactions',
                  icon: 'menu-icon flaticon-home-1',
                  permission: 'inventory-transactions.list'
                },
                {
                  name: this.$t('MENU.Inventory_monitoring'),
                  link: '/items/inventory-monitoring',
                  icon: 'menu-icon fas fa-warehouse',
                  permission: 'inventory-monitoring.list'
                },
                {
                  name: this.$t('MENU.inventory_checking'),
                  link: '/items/inventory-checking',
                  icon: 'menu-icon la la-warehouse',
                  permission: 'inventory_checking.list'
                },
                {
                  name: this.$t('MENU.inventory_statistics'),
                  link: '/items/inventory_statistics',
                  icon: 'menu-icon flaticon2-line-chart',
                  permission: 'items.inventory_statistics'
                },
                {
                  name: this.$t('MENU.inventory_statistics_tracking'),
                  link: '/items/inventory_statistics_tracking',
                  icon: 'menu-icon flaticon2-line-chart',
                  permission: 'items.inventory_statistics'
                }
              ]
            },
            {
              name: this.$t('MENU.item_configuration'), icon: 'menu-icon flaticon2-supermarket',
              active_link: ['/items/item_label_configuration'],
              permission: ['item_configuration.update', 'item_table_setting.update'],
              sub_links: [
                {
                  name: this.$t('MENU.item_label_management'),
                  link: '/items/item_label_configuration',
                  icon: 'menu-icon flaticon2-gear',
                  permission: 'item_configuration.update'
                },
                {
                  name: this.$t('item_table_settings'),
                  link: '/items/item_table_settings',
                  icon: 'menu-icon flaticon2-gear',
                  permission: 'item_table_setting.update'
                },
                // {name: this.$t('MENU.sales_tax_settings'), link: '/sales/sales-tax-settings', icon: 'menu-icon flaticon2-supermarket', permission: 'tax_setting_sales.update'},
              ]
            },

            // {name: this.$t('MENU.open_quantity_history'), link: '/items/open-quantity-history', icon: 'menu-icon la la-stream', permission: 'item_open_stock.list'},
            // {name: this.$t('MENU.inventory_checking_history'), link: '/items/inventory-checking-history', icon: 'menu-icon la la-warehouse', permission: 'inventory_checking.list'},
            // {name: this.$t('MENU.export_import_items'), link: '/items/import-items', icon: 'menu-icon la la-warehouse', permission: 'export_import_items.list'},


          ]
        },

        /*********************** Purchases Module****************************/
        {
          name: this.$t('MENU.purchases'),
          link: null,
          icon: 'menu-icon flaticon-network',
          active: '/purchases',
          permission: ['suppliers.list', 'supplier_checking.list', 'purchases_invoices.list', 'purchases_refunds.list', 'purchases-settings.update', 'subscriptions.list', 'purchases_requests.list', 'quotations_requests.list', 'purchase_orders.list', 'tax_setting_purchase.update', 'receipt_documents.list', 'payment_purchase_invoices.list', 'suppliers.export_import_suppliers'],
          sub_links: [
            {
              name: this.$t('MENU.purchases_invoices'),
              link: '/purchases/purchases-invoices',
              icon: 'menu-icon flaticon-coins',
              permission: 'purchases_invoices.list'
            },
            {
              name: this.$t('MENU.purchases_refunds'),
              link: '/purchases/purchases-refunds',
              icon: 'menu-icon flaticon2-browser-2',
              permission: 'purchases_refunds.list'
            },
            {
              name: this.$t('MENU.payment_purchase_invoices'),
              link: '/purchases/payment_purchase_invoices',
              icon: 'menu-icon flaticon-coins',
              permission: 'payment_purchase_invoices.list'
            },

            {
              name: this.$t('MENU.orders_and_offers_purchases'), icon: 'menu-icon flaticon2-shopping-cart-1',
              active_link: ['/purchases/quotation-requests', '/purchases/purchase-quotations', '/purchases/purchases-orders', '/purchases/receipt-documents'],
              permission: ['quotations_requests.list', 'purchase_quotations.list', 'purchase_orders.list', 'receipt_documents.list'],
              sub_links: [
                {
                  name: this.$t('MENU.purchases_quotation_requests'),
                  link: '/purchases/quotation-requests',
                  icon: 'menu-icon flaticon2-digital-marketing',
                  permission: 'quotations_requests.list'
                },
                {
                  name: this.$t('MENU.purchase_quotations'),
                  link: '/purchases/purchase-quotations',
                  icon: 'menu-icon flaticon2-supermarket',
                  permission: 'purchase_quotations.list'
                },
                {
                  name: this.$t('MENU.purchases_orders'),
                  link: '/purchases/purchases-orders',
                  icon: 'menu-icon flaticon2-supermarket',
                  permission: 'purchase_orders.list'
                },
                {
                  name: this.$t('MENU.receipt_documents'),
                  link: '/purchases/receipt-documents',
                  icon: 'menu-icon flaticon-coins',
                  permission: 'receipt_documents.list'
                },

              ]
            },
            {
              name: this.$t('MENU.purchases_requests'),
              link: '/purchases/purchases-requests',
              icon: 'menu-icon flaticon2-shopping-cart-1',
              permission: 'purchases_requests.list'
            },

            {
              name: this.$t('MENU.suppliers_management'), icon: 'menu-icon flaticon-network',
              active_link: ['/purchases/suppliers', '/purchases/supplier-checking'],
              permission: ['suppliers.list', 'supplier_checking.list'],
              sub_links: [
                {
                  name: this.$t('MENU.suppliers_management'),
                  link: '/purchases/suppliers',
                  icon: 'menu-icon flaticon-network',
                  permission: 'suppliers.list'
                },
                {
                  name: this.$t('MENU.supplier_checking'),
                  link: '/purchases/supplier-checking',
                  icon: 'menu-icon flaticon-network',
                  permission: 'supplier_checking.list'
                },
              ]
            },

            {
              name: this.$t('MENU.subscriptions_management'),
              link: '/purchases/subscriptions',
              icon: 'menu-icon flaticon2-analytics',
              permission: 'subscriptions.list'
            },

            // {name: this.$t('MENU.supplier_checking_history'), link: '/purchases/supplier-checking-history', icon: 'menu-icon flaticon2-list-2', permission: 'supplier_checking.list'},
            {
              name: this.$t('MENU.purchase_configuration'),
              link: '/purchases/purchase-configuration',
              icon: 'menu-icon flaticon-app',
              permission: 'purchases-settings.update'
            },
            // {name: this.$t('MENU.purchase_tax_settings'), link: '/purchases/purchase-tax-settings', icon: 'menu-icon flaticon2-supermarket', permission: 'tax_setting_purchase.update'},
          ]
        },

        /*********************** Sales Module****************************/
        /*********************** Sales Module****************************/

        /*********************** Sales Module****************************/

        {
          name: this.$t('MENU.sales'),
          link: null,
          icon: 'menu-icon flaticon-cart',
          active: '/sales',
          permission: ['estimations.list', 'sales_invoices.list', 'credits.list', 'sales_shipping.list', 'sales_refund.list', 'sales_configuration.update', 'payment_sales_invoices.list', 'general_sales_refund.list', 'tax_setting_sales.update', 'general_credits.list', 'general_debit.list', 'sales_laundry_services.list'],
          sub_links: [
            {
              name: this.$t('MENU.sales_invoices_management'),
              link: '/sales/sales_invoices',
              icon: 'menu-icon flaticon-list-1',
              permission: 'sales_invoices.list'
            },
            {
              name: this.$t('MENU.general_sales'),
              link: '/sales/general_sales',
              icon: 'menu-icon flaticon-list-1',
              permission: 'general_sales.list'
            },
            {
              name: this.$t('MENU.laundry_sales_invoices_management'),
              link: '/sales/sales-laundries',
              icon: 'menu-icon flaticon-list-1',
              permission: 'sales_laundry_services.list'
            },

            {
              name: this.$t('MENU.general_rent_invoices'),
              link: '/sales/general_sales_rent',
              icon: 'menu-icon flaticon-list-1',
              permission: 'general_sales_rent.list'
            },
            {
              name: this.$t('MENU.rent_invoices'),
              link: '/sales/sales_rent',
              icon: 'menu-icon flaticon-list-1',
              permission: 'sales_rent.list'
            },


            {
              name: this.$t('MENU.sales_management'), icon: 'menu-icon flaticon-cart',
              active_link: ['/sales/sales_refund', '/sales/general_sales_refund', '/sales/credits', '/sales/debits', '/sales/payment_sales_invoices', '/sales/sales_shipping', '/sales/general-credits', '/sales/general-debits'],
              permission: ['sales_refund.list', 'general_sales_refund.list', 'credits.list', 'sales_debit.list', 'payment_sales_invoices.list', 'sales_shipping.list', 'general_credits.list', 'general_debit.list'],
              sub_links: [
                {
                  name: this.$t('MENU.sales_refund'),
                  link: '/sales/sales_refund',
                  icon: 'menu-icon flaticon-coins',
                  permission: 'sales_refund.list'
                },
                {
                  name: this.$t('MENU.general_sales_refund'),
                  link: '/sales/general_sales_refund',
                  icon: 'menu-icon flaticon-coins',
                  permission: 'general_sales_refund.list'
                },
                {
                  name: this.$t('MENU.sales_credits_management'),
                  link: '/sales/credits',
                  icon: 'menu-icon flaticon-suitcase',
                  permission: 'credits.list'
                },
                {
                  name: this.$t('MENU.sales_debits_management'),
                  link: '/sales/debits',
                  icon: 'menu-icon flaticon-suitcase',
                  permission: 'sales_debit.list'
                },
                {
                  name: this.$t('MENU.general_sales_credits_management'),
                  link: '/sales/general-credits',
                  icon: 'menu-icon flaticon-suitcase',
                  permission: 'general_credits.list'
                },
                {
                  name: this.$t('MENU.general_sales_debits_management'),
                  link: '/sales/general-debits',
                  icon: 'menu-icon flaticon-suitcase',
                  permission: 'general_debit.list'
                },
                {
                  name: this.$t('MENU.payment_sales_invoices'),
                  link: '/sales/payment_sales_invoices',
                  icon: 'menu-icon flaticon-coins',
                  permission: 'payment_sales_invoices.list'
                },
                {
                  name: this.$t('MENU.sales_shipping_management'),
                  link: '/sales/sales_shipping',
                  icon: 'menu-icon flaticon-truck',
                  permission: 'sales_shipping.list'
                },
              ]
            },


            {
              name: this.$t('MENU.quotes_and_estimates'), icon: 'menu-icon flaticon-folder-1',
              active_link: ['/sales/sales-quotation-requests', '/sales/sales-quotations', '/sales/estimations', '/sales/general-sales-quotations'],
              permission: ['sales_quotation_request.list', 'sales_quotations.list', 'estimations.list', 'general_sales_quotations.list'],
              sub_links: [
                {
                  name: this.$t('MENU.sales_quotation_requests'),
                  link: '/sales/sales-quotation-requests',
                  icon: 'menu-icon flaticon-folder-1',
                  permission: 'sales_quotation_request.list'
                },
                {
                  name: this.$t('MENU.sales_quotations'),
                  link: '/sales/sales-quotations',
                  icon: 'menu-icon flaticon-cart',
                  permission: 'sales_quotations.list'
                },
                {
                  name: this.$t('MENU.general_sales_quotations'),
                  link: '/sales/general-sales-quotations',
                  icon: 'menu-icon flaticon-cart',
                  permission: 'general_sales_quotations.list'
                },
                {
                  name: this.$t('MENU.estimations_management'),
                  link: '/sales/estimations',
                  icon: 'menu-icon flaticon-folder-1',
                  permission: 'estimations.list'
                },

              ]
            },

            {
              name: this.$t('MENU.sales_configuration'), icon: 'menu-icon flaticon2-supermarket',
              active_link: ['/sales/sales_configuration', '/sales/sales-tax-settings',],
              permission: ['sales_configuration.update', 'tax_setting_sales.update'],
              sub_links: [
                {
                  name: this.$t('MENU.sales_configuration_management'),
                  link: '/sales/sales_configuration',
                  icon: 'menu-icon flaticon2-gear',
                  permission: 'sales_configuration.update'
                },
                // {name: this.$t('MENU.sales_tax_settings'), link: '/sales/sales-tax-settings', icon: 'menu-icon flaticon2-supermarket', permission: 'tax_setting_sales.update'},
              ]
            },


          ]
        },

        /***********************Customers Module****************************/
        {
          name: this.$t('MENU.customers'),
          link: null,
          icon: 'menu-icon flaticon-users-1',
          active: '/customers',
          permission: ['customers.list', 'customer-status.update', 'customer-attributes.update', 'customer-features.update', 'appointments.list', 'customer_checking.list', 'customers.export_import_customers'],
          sub_links: [
            {
              name: this.$t('MENU.customers_management'),
              link: '/customers/customers',
              icon: 'menu-icon flaticon-users-1',
              permission: 'customers.list'
            },
            {
              name: this.$t('MENU.customer_checking'),
              link: '/customers/customer-checking',
              icon: 'menu-icon flaticon-clipboard',
              permission: 'customer_checking.list'
            },
            {
              name: this.$t('MENU.appointments'),
              link: '/customers/appointments',
              icon: 'menu-icon flaticon-calendar-2',
              permission: 'appointments.list'
            },

            {
              name: this.$t('MENU.customers_configurations'), icon: 'menu-icon flaticon-notes',
              active_link: ['/customers/customers-status', '/customers/customer-attributes', '/customers/customer-features'],
              permission: ['customer-status.update', 'customer-attributes.update', 'customer-features.update'],
              sub_links: [
                {
                  name: this.$t('MENU.customers_status'),
                  link: '/customers/customers-status',
                  icon: 'menu-icon flaticon-rotate',
                  permission: 'customer-status.update'
                },
                {
                  name: this.$t('MENU.customer_attributes'),
                  link: '/customers/customer-attributes',
                  icon: 'menu-icon flaticon-notes',
                  permission: 'customer-attributes.update'
                },
                {
                  name: this.$t('MENU.customers_features'),
                  link: '/customers/customer-features',
                  icon: 'menu-icon flaticon-notes',
                  permission: 'customer-features.update'
                },

              ]
            },

            // {name: this.$t('MENU.customer_checking_history'), link: '/customers/customer-checking-history', icon: 'menu-icon flaticon2-list-2', permission: 'customer_checking.list'},
            // {name: this.$t('MENU.export_import_customers'), link: '/customers/export-import-customers', icon: 'menu-icon la la-warehouse', permission: 'customers.export_import_customers'},

          ]
        },

        /*********************** POS Module****************************/
        {
          name: this.$t('MENU.POS'),
          link: null,
          icon: 'menu-icon flaticon-imac',
          active: '/pos',
          permission: ['pos_devices.list', 'pos_shifts.list', 'pos_settings.update', 'pos_session.list', 'point_sales.list', 'pos_stores.list', 'point_sales_laundry.list', 'laundry_subscriptions.list'],
          sub_links: [
            {
              name: this.$t('MENU.point_of_sales'),
              link: '/point-of-sales/point-of-sales',
              icon: 'menu-icon flaticon-imac',
              permission: 'point_sales.list'
            },
            {
              name: this.$t('MENU.point_of_sales_laundry'),
              link: '/point-of-sales/point-of-sales-laundry',
              icon: 'menu-icon flaticon-imac',
              permission: 'point_sales_laundry.list'
            },
            {
              name: this.$t('MENU.pos_sessions'),
              link: '/pos/pos-session',
              icon: 'menu-icon flaticon-imac',
              permission: 'pos_session.list'
            },
            {
              name: this.$t('MENU.laundry_subscriptions'),
              link: '/pos/laundry-subscriptions',
              icon: 'menu-icon flaticon-imac',
              permission: 'laundry_subscriptions.list'
            },
            {
              name: this.$t('MENU.pos_settings'), icon: 'menu-icon flaticon-folder-1',
              active_link: ['/pos/pos-stores', '/pos/pos-devices', '/pos/pos-shifts', '/pos/pos-settings'],
              permission: ['pos_stores.list', 'pos_devices.list', 'pos_shifts.list', 'pos_settings.update'],
              sub_links: [
                {
                  name: this.$t('MENU.pos_stores'),
                  link: '/pos/pos-stores',
                  icon: 'menu-icon flaticon2-gear',
                  permission: 'pos_stores.list'
                },
                {
                  name: this.$t('MENU.pos_devices'),
                  link: '/pos/pos-devices',
                  icon: 'menu-icon flaticon-responsive',
                  permission: 'pos_devices.list'
                },
                {
                  name: this.$t('MENU.pos_shifts'),
                  link: '/pos/pos-shifts',
                  icon: 'menu-icon flaticon-layer',
                  permission: 'pos_shifts.list'
                },
                {
                  name: this.$t('MENU.pos_settings'),
                  link: '/pos/pos-settings',
                  icon: 'menu-icon flaticon-settings-1',
                  permission: 'pos_settings.update'
                },
              ]
            },
          ]
        },

        /*********************** Finances Module****************************/
        {
          name: this.$t('MENU.Finances_management'),
          link: null,
          icon: 'menu-icon flaticon2-graphic',
          active: '/finances',
          permission: ['expenses.list', 'incomes.list', 'banks.list', 'treasuries.list', 'income_categories.list', 'expenses_category.list', 'treasury_checking.list', 'expenses_incomes.list', 'bank_checking.list'],
          sub_links: [
            {
              name: this.$t('MENU.expenses_management'),
              link: '/finances/expenses',
              icon: 'menu-icon flaticon-coins',
              permission: 'expenses.list'
            },
            {
              name: this.$t('MENU.incomes_management'),
              link: '/finances/incomes',
              icon: 'menu-icon flaticon-download-1',
              permission: 'incomes.list'
            },
            {
              name: this.$t('MENU.expenses_income_transaction'),
              link: '/finances/expenses-income-transaction',
              icon: 'menu-icon flaticon-list-1',
              permission: 'expenses_incomes.list'
            },

            {
              name: this.$t('MENU.cash_management'), icon: 'menu-icon flaticon2-list-2',
              active_link: ['/finances/banks', '/finances/treasuries', '/finances/treasury-checking', '/finances/bank-checking',],
              permission: ['banks.list', 'treasuries.list', 'treasury_checking.list', 'bank_checking.list'],
              sub_links: [
                {
                  name: this.$t('MENU.treasuries_management'),
                  link: '/finances/treasuries',
                  icon: 'menu-icon flaticon-interface-3',
                  permission: 'treasuries.list'
                },
                {
                  name: this.$t('MENU.banks_management'),
                  link: '/finances/banks',
                  icon: 'menu-icon flaticon-home',
                  permission: 'banks.list'
                },
                {
                  name: this.$t('MENU.treasury_checking'),
                  link: '/finances/treasury-checking',
                  icon: 'menu-icon flaticon-clipboard',
                  permission: 'treasury_checking.list'
                },
                {
                  name: this.$t('MENU.bank_checking'),
                  link: '/finances/bank-checking',
                  icon: 'menu-icon flaticon-clipboard',
                  permission: 'bank_checking.list'
                },

                // {name: this.$t('MENU.treasury_checking_history'), link: '/finances/treasury-checking-history', icon: 'menu-icon flaticon2-list-2', permission: 'treasury_checking.list'},
                // {name: this.$t('MENU.bank_checking_history'), link: '/finances/bank-checking-history', icon: 'menu-icon flaticon2-list-2', permission: 'bank_checking.list'},

              ]
            },

            {
              name: this.$t('MENU.financial_settings'), icon: 'menu-icon flaticon-folder-1',
              active_link: ['/finances/income_categories', '/finances/expenses_category'],
              permission: ['income_categories.list', 'expenses_category.list'],
              sub_links: [
                {
                  name: this.$t('MENU.IncomeCategories'),
                  link: '/finances/income_categories',
                  icon: 'menu-icon flaticon-open-box',
                  permission: 'income_categories.list'
                },
                {
                  name: this.$t('MENU.expenses_categories'),
                  link: '/finances/expenses_category',
                  icon: 'menu-icon flaticon-notepad',
                  permission: 'expenses_category.list'
                },
              ]
            },

          ]
        },

        /*********************** Accounting Module****************************/
        {
          name: this.$t('MENU.accounting'),
          link: null,
          icon: 'menu-icon flaticon2-list',
          active: '/accounting',
          permission: ['financial_years.list', 'closed_periods.list', 'chart_accounts.list', 'secondary_accounts.list', 'assets_info.list', 'accounts_routing.update', 'cost_centers.list', 'accounts_without_cost_center.list', 'accounts_with_cost_center.list', 'cost_center_transactions.list', 'jornal_entries.list', 'secondary_accounts.list', 'groups_accounts.list', 'document_type_setting.list', 'journal_settings.list', 'chart_accounts_settings.update'],
          sub_links: [
            {
              name: this.$t('MENU.journal_entries'),
              link: '/accounting/journal-entries',
              icon: 'menu-icon flaticon-graphic-2',
              permission: 'jornal_entries.list'
            },
            {
              name: this.$t('MENU.chart_accounts'),
              link: '/accounting/chart-accounts',
              icon: 'menu-icon flaticon2-line-chart',
              permission: 'chart_accounts.list'
            },

            /**
             * general settings
             */
            {
              name: this.$t('MENU.general_settings'), icon: 'menu-icon flaticon-folder-1',
              active_link: ['/accounting/financial-years', '/accounting/closed-periods', '/accounting/chart-account-setting', '/accounting/accounts-routing', '/accounting/groups-accounts'],
              permission: ['financial_years.list', 'closed_periods.list', 'chart_accounts.list', 'accounts_routing.update', 'groups_accounts.list', 'chart_accounts_settings.update'],
              sub_links: [
                {
                  name: this.$t('MENU.financial_years'),
                  link: '/accounting/financial-years',
                  icon: 'menu-icon flaticon-event-calendar-symbol',
                  permission: 'financial_years.list'
                },
                {
                  name: this.$t('MENU.closed_periods'),
                  link: '/accounting/closed-periods',
                  icon: 'menu-icon flaticon-lock',
                  permission: 'closed_periods.list'
                },
                {
                  name: this.$t('MENU.chart_account_setting'),
                  link: '/accounting/chart-account-setting',
                  icon: 'menu-icon flaticon2-line-chart',
                  permission: 'chart_accounts_settings.update'
                },
                {
                  name: this.$t('MENU.accounts_routing'),
                  link: '/accounting/accounts-routing',
                  icon: 'menu-icon flaticon-rotate',
                  permission: 'accounts_routing.update'
                },
                {
                  name: this.$t('MENU.groups_accounts'),
                  link: '/accounting/groups-accounts',
                  icon: 'menu-icon flaticon2-line-chart',
                  permission: 'groups_accounts.list'
                },

              ]
            },


            /**
             * journals management
             */
            {
              name: this.$t('MENU.journals_management'), icon: 'menu-icon flaticon-folder-1',
              active_link: ['/accounting/document-type-setting', '/accounting/journals-setting',],
              permission: ['document_type_setting.list', 'journal_settings.list'],
              sub_links: [
                {
                  name: this.$t('MENU.document_type_setting'),
                  link: '/accounting/document-type-setting',
                  icon: 'menu-icon flaticon-interface-11',
                  permission: 'document_type_setting.list'
                },
                {
                  name: this.$t('MENU.journals_setting'),
                  link: '/accounting/journals-setting',
                  icon: 'menu-icon flaticon2-gear',
                  permission: 'journal_settings.list'
                },
              ]
            },

            /**
             * cost centers management
             */
            {
              name: this.$t('MENU.cost_centers_management'), icon: 'menu-icon flaticon-folder-1',
              active_link: ['/accounting/cost-centers', '/accounting/accounts-without-cost-center', '/accounting/accounts-with-cost-center', '/accounting/cost-center-transactions',],
              permission: ['cost_centers.list', 'accounts_without_cost_center.list', 'accounts_with_cost_center.list', 'cost_center_transactions.list'],
              sub_links: [
                {
                  name: this.$t('MENU.cost_centers'),
                  link: '/accounting/cost-centers',
                  icon: 'menu-icon flaticon-multimedia-3',
                  permission: 'cost_centers.list'
                },
                {
                  name: this.$t('MENU.accounts_without_cost_center'),
                  link: '/accounting/accounts-without-cost-center',
                  icon: 'menu-icon flaticon-interface-1',
                  permission: 'accounts_without_cost_center.list'
                },
                {
                  name: this.$t('MENU.accounts_with_cost_center'),
                  link: '/accounting/accounts-with-cost-center',
                  icon: 'menu-icon flaticon2-list',
                  permission: 'accounts_with_cost_center.list'
                },
                {
                  name: this.$t('MENU.cost_center_transactions'),
                  link: '/accounting/cost-center-transactions',
                  icon: 'menu-icon flaticon-graphic-2',
                  permission: 'cost_center_transactions.list'
                },
              ]
            },

            {
              name: this.$t('MENU.secondary_accounts_tree'),
              link: '/accounting/secondary-accounts-tree',
              icon: 'menu-icon flaticon2-line-chart',
              permission: 'secondary_accounts.list'
            },
            {
              name: this.$t('MENU.assets_info'),
              link: '/accounting/assets-info',
              icon: 'menu-icon flaticon-information',
              permission: 'assets_info.list'
            },

          ]
        },

        /*********************** Manufacturers Module ****************************/
        {

          name: this.$t('MENU.manufacturers'),
          link: null,
          icon: 'menu-icon flaticon-security',
          active: '/manufactories',
          permission: ['workstation.list', 'quality_inspection.list', 'operation.list', 'routing_management.list', 'material.list', 'bill_material.list', 'work_order.list', 'job_card.list'],
          sub_links: [
            {
              name: this.$t('MENU.bill_of_material'),
              link: '/manufactories/bill-of-material',
              icon: 'menu-icon flaticon-customer',
              permission: 'bill_material.list'
            },
            {
              name: this.$t('MENU.work_order'),
              link: '/manufactories/work-order',
              icon: 'menu-icon flaticon-customer',
              permission: 'work_order.list'
            },
            {
              name: this.$t('MENU.job_card'),
              link: '/manufactories/job-card',
              icon: 'menu-icon flaticon-customer',
              permission: 'job_card.list'
            },
            {
              name: this.$t('MENU.material_list'),
              link: '/manufactories/material-list',
              icon: 'menu-icon flaticon-customer',
              permission: 'material.list'
            },

            {
              name: this.$t('MENU.manufacturers_management'), icon: 'menu-icon flaticon2-list-2',
              active_link: ['/manufactories/workstation', '/manufactories/routing-management', '/manufactories/operation-management', '/manufactories/quality-inspections',],
              permission: ['workstation.list', 'routing_management.list', 'operation.list', 'quality_inspection.list'],
              sub_links: [
                {
                  name: this.$t('MENU.workstation'),
                  link: '/manufactories/workstation',
                  icon: 'menu-icon flaticon-customer',
                  permission: 'workstation.list'
                },
                {
                  name: this.$t('MENU.routing_management'),
                  link: '/manufactories/routing-management',
                  icon: 'menu-icon flaticon-customer',
                  permission: 'routing_management.list'
                },
                {
                  name: this.$t('MENU.operation_management'),
                  link: '/manufactories/operation-management',
                  icon: 'menu-icon flaticon-customer',
                  permission: 'operation.list'
                },
                {
                  name: this.$t('MENU.quality_inspections'),
                  link: '/manufactories/quality-inspections',
                  icon: 'menu-icon flaticon-customer',
                  permission: 'quality_inspection.list'
                },
              ]
            },
          ]
        },

        /*********************** cheques Cycle Module****************************/
        {
          name: this.$t('MENU.cheques_cycle'),
          link: null,
          icon: 'menu-icon flaticon-share',
          active: '/cheques-cycle',
          permission: ['book_cheques.list', 'payable_cheques.list', 'receivable_cheques.list'],
          sub_links: [
            {
              name: this.$t('MENU.cheque_book'),
              link: '/cheques-cycle/cheque-book',
              icon: 'menu-icon flaticon-notes',
              permission: 'book_cheques.list'
            },
            {
              name: this.$t('MENU.payable_cheques'),
              link: '/cheques-cycle/payable-cheques',
              icon: 'menu-icon flaticon-calendar-with-a-clock-time-tools',
              permission: 'payable_cheques.list'
            },
            {
              name: this.$t('MENU.receivable_cheques'),
              link: '/cheques-cycle/receivable-cheques',
              icon: 'menu-icon flaticon-notes',
              permission: 'receivable_cheques.list'
            },
          ]
        },

        /*********************** Reports Module****************************/
        {
          name: this.$t('MENU.reports'),
          link: null,
          icon: 'menu-icon flaticon-analytics',
          active: '/reports',
          permission: ['report_stores.list', 'report_clients.list', 'report_sales.list', 'report_purchase.list', 'report_accounting.list', 'report_financial_transactions.list', 'report_items.list', 'report_system_activity_log.list'],
          sub_links: [
            {
              name: this.$t('MENU.store_reports'),
              link: '/store/reports',
              icon: 'menu-icon flaticon2-architecture-and-city',
              permission: 'report_stores.list'
            },
            {
              name: this.$t('MENU.clients_reports'),
              link: '/clients/reports',
              icon: 'menu-icon flaticon2-avatar',
              permission: 'report_clients.list'
            },
            {
              name: this.$t('MENU.sales_reports'),
              link: '/reports/sales-reports-links',
              icon: 'menu-icon flaticon2-supermarket',
              permission: 'report_sales.list'
            },
            {
              name: this.$t('MENU.purchases_reports'),
              link: '/reports/purchase-reports-links',
              icon: 'menu-icon flaticon2-shopping-cart-1',
              permission: 'report_purchase.list'
            },
            {
              name: this.$t('MENU.accounting_reports'),
              link: '/reports/accounting-reports',
              icon: 'menu-icon flaticon2-shopping-cart-1',
              permission: 'report_accounting.list'
            },
            {
              name: this.$t('MENU.financial_transactions_report'),
              link: '/reports/financial-transactions-report',
              icon: 'menu-icon flaticon2-shopping-cart-1',
              permission: 'report_financial_transactions.list'
            },
            {
              name: this.$t('MENU.items_report'),
              link: '/reports/items-reports-links',
              icon: 'menu-icon flaticon2-shopping-cart-1',
              permission: 'report_items.list'
            },
            {
              name: this.$t('MENU.activity_log_report'),
              link: {name: 'system-activity-log.list'},
              icon: 'menu-icon flaticon2-shopping-cart-1',
              permission: 'report_system_activity_log.list'
            },
          ]
        },

        /*********************** Loyalty Module ****************************/
        {
          name: this.$t('MENU.loyalty'),
          link: null,
          icon: 'menu-icon flaticon-security',
          active: '/loyalty',
          permission: ['clients_loyalty.update', 'loyalty_rules.list'],
          sub_links: [
            {
              name: this.$t('MENU.clients_loyalty'),
              link: '/loyalty/clients-loyalty',
              icon: 'menu-icon flaticon-customer',
              permission: 'clients_loyalty.update'
            },
            {
              name: this.$t('MENU.loyalty_rules'),
              link: '/loyalty/loyalty-rules',
              icon: 'menu-icon flaticon-book',
              permission: 'loyalty_rules.list'
            },
          ]
        },

        /*********************** Installments Module ****************************/
        {
          name: this.$t('MENU.installments'),
          link: null,
          icon: 'menu-icon flaticon-list',
          active: '/installment',
          permission: ['installments.list', 'installment_payments.list'],
          sub_links: [
            {
              name: this.$t('MENU.installment_agreements'),
              link: '/installment/installment',
              icon: 'menu-icon flaticon-edit-1',
              permission: 'installments.list'
            },
            {
              name: this.$t('MENU.installments_payments'),
              link: '/installment/payments',
              icon: 'menu-icon flaticon-coins',
              permission: 'installment_payments.list'
            },
          ]
        },

        /*********************** Insurance Agents Module ****************************/
        {
          name: this.$t('MENU.insuranceagents'),
          link: null,
          icon: 'menu-icon flaticon2-group',
          active: '/insuranceagents',
          permission: ['insurance_agents.list'],
          sub_links: [
            {
              name: this.$t('MENU.insurance_agents'),
              link: '/insuranceagents/insurance-agents',
              icon: 'menu-icon flaticon2-group',
              permission: 'insurance_agents.list'
            },
          ]
        },

        /*********************** Sales Target Commission Module ****************************/
        {

          name: this.$t('MENU.sales_target_commission'),
          link: null,
          icon: 'menu-icon flaticon-squares-3',
          active: '/salestargetcommission',
          permission: ['sales_commission.list', 'sales_period.list', 'sales_commission_details.list'],
          sub_links: [
            {
              name: this.$t('MENU.sales_commissions'),
              link: '/salestargetcommission/sales-commissions',
              icon: 'menu-icon flaticon-line-graph',
              permission: 'sales_commission.list'
            },
            {
              name: this.$t('MENU.sales_periods'),
              link: '/salestargetcommission/sales-periods',
              icon: 'menu-icon flaticon-calendar-with-a-clock-time-tools',
              permission: 'sales_period.list'
            },
            {
              name: this.$t('MENU.sales_commission_details'),
              link: '/salestargetcommission/sales-commission-details',
              icon: 'menu-icon flaticon2-copy',
              permission: 'sales_commission_details.list'
            },
          ]
        },

        /*********************** Points Credits Module ****************************/
        {
          name: this.$t('MENU.points_credits'),
          link: null,
          icon: 'menu-icon flaticon-grid-menu-v2',
          active: '/points-credits',
          permission: ['credit_type.list', 'credit_usage.list', 'package_info.list'],
          sub_links: [
            {
              name: this.$t('MENU.credit_types'),
              link: '/points-credits/credit-types',
              icon: 'menu-icon flaticon-layer',
              permission: 'credit_type.list'
            },
            {
              name: this.$t('MENU.credit_usages'),
              link: '/points-credits/credit-usages',
              icon: 'menu-icon flaticon-layer',
              permission: 'credit_usage.list'
            },
            {
              name: this.$t('MENU.package_info'),
              link: '/points-credits/package-info',
              icon: 'menu-icon flaticon-layer',
              permission: 'package_info.list'
            },
          ]
        },


        /***********************employees Module****************************/
        {
          name: this.$t('MENU.employees_management'),
          link: null,
          icon: 'menu-icon flaticon2-user-1',
          active: '/employees',
          permission: ['employees.list', 'departments.list'],
          sub_links: [
            {
              name: this.$t('MENU.employees'),
              link: '/employees/employees',
              icon: 'menu-icon flaticon-user',
              permission: 'employees.list'
            },
            {
              name: this.$t('MENU.departments'),
              link: '/employees/departments',
              icon: 'menu-icon flaticon-settings-1',
              permission: 'departments.list'
            },
          ]
        },

        /***********************User Module****************************/
        {
          name: this.$t('MENU.users_roles_management'),
          link: null,
          icon: 'menu-icon flaticon2-user-1',
          active: '/users',
          permission: ['users.list', 'roles.list', 'send_message_users.update'],
          sub_links: [
            {
              name: this.$t('MENU.USERS'),
              link: '/users/users',
              icon: 'menu-icon flaticon2-user-1',
              permission: 'users.list'
            },
            {
              name: this.$t('MENU.send_message_users'),
              link: '/users/send/message',
              icon: 'menu-icon flaticon2-chat-2',
              permission: 'send_message_users.update'
            },
            {
              name: this.$t('MENU.Roles_Management'),
              link: '/users/roles',
              icon: 'menu-icon flaticon-settings-1',
              permission: 'roles.list'
            },
            {
              name: this.$t('MENU.data_access_configuration'),
              link: '/users/data-access-configuration',
              icon: 'menu-icon flaticon-settings-1',
              permission: ''
            },//data_access_configuration.list
            // {name: this.$t('MENU.default_data'), link: '/users/default-data', icon: 'menu-icon flaticon-settings-1', permission: ''},//default_data.list
          ]
        },

        /***********************Setting Module****************************/
        {
          name: this.$t('MENU.setting_management'),
          link: null,
          icon: 'menu-icon flaticon2-settings',
          active: '/settings',
          permission: ['discounts_setting.list', 'constants.list', 'code_setting.list', 'procedures.list', 'units.list', 'payments.list', 'taxes.list', 'countries.list', 'branches.list', 'smtps.update', 'barcode_setting.update', 'template_design.update', 'inventories.list', 'api-configurations.list', 'key_generations.list', 'api_internal_configurations.list', 'brands.list', 'manufacturers.list', 'categories.list', 'currencies_convert.list', 'notification_config.list', 'general_group.list', 'taxes_rules.list', 'tax_rules_details.list', 'laundry_services_colors.update', 'cash_category.list', 'invoice_bond_configuration.list', 'basic_status_management.list', 'document_status_paths.list', 'status_mapping.list', 'connected_printers.list', 'fabric_problems.list', 'attached_reasons.list'],
          sub_links: [
            /**
             * main settings
             */
            {
              name: this.$t('MENU.main_settings'), icon: 'menu-icon flaticon-folder-1',
              active_link: ['/settings/branches', '/settings/inventories', '/settings/categories', '/settings/units', '/settings/general-group', '/settings/manufacturers', '/settings/brands', '/settings/laundry-services', '/settings/categories/list', '/settings/laundry-services-colors', '/settings/basic-status-management', '/settings/document-status-paths', '/settings/status-mapping', '/settings/connected-printers', '/settings/fabric-problems', '/settings/attached-reasons', '/settings/discount'],
              permission: ['discounts_setting.list', 'branches.list', 'inventories.list', 'categories.list', 'units.list', 'general_group.list', 'manufacturers.list', 'brands.list', 'laundry_services.list', 'laundry_services_colors.update', 'basic_status_management.list', 'document_status_paths.list', 'status_mapping.list', 'connected_printers.list', 'fabric_problems.list', 'attached_reasons.list'],
              sub_links: [
                // {
                //   name: this.$t('discounts_management'),
                //   link: '/settings/discount',
                //   icon: 'menu-icon flaticon2-gear',
                //   permission: 'discounts_setting.list'
                // },
                {
                  name: this.$t('MENU.mobile_app_settings'),
                  link: '/settings/mobile-app-settings',
                  icon: 'menu-icon la  la-mobile',
                  permission: 'mobile_app_setting.list'
                },
                {
                  name: this.$t('MENU.Branches'),
                  link: '/settings/branches',
                  icon: 'menu-icon flaticon-map',
                  permission: 'branches.list'
                },
                {
                  name: this.$t('MENU.Inventories'),
                  link: '/settings/inventories',
                  icon: 'menu-icon flaticon-home',
                  permission: 'inventories.list'
                },
                {
                  name: this.$t('MENU.Categories'),
                  link: '/settings/categories',
                  icon: 'menu-icon la la-sitemap',
                  permission: 'categories.list'
                },
                {
                  name: this.$t('MENU.categories_list'),
                  link: '/settings/categories/list',
                  icon: 'menu-icon la la-sitemap',
                  permission: 'categories.list'
                },
                {
                  name: this.$t('MENU.units'),
                  link: '/settings/units',
                  icon: 'menu-icon flaticon2-soft-icons',
                  permission: 'units.list'
                },
                {
                  name: this.$t('MENU.general_group'),
                  link: '/settings/general-group',
                  icon: 'menu-icon flaticon2-gear',
                  permission: 'general_group.list'
                },
                {
                  name: this.$t('MENU.Manufacturers'),
                  link: '/settings/manufacturers',
                  icon: 'menu-icon la la-solar-panel',
                  permission: 'manufacturers.list'
                },
                {
                  name: this.$t('MENU.brands_management'),
                  link: '/settings/brands',
                  icon: 'menu-icon flaticon-medal',
                  permission: 'brands.list'
                },
                {
                  name: this.$t('MENU.laundry_services'),
                  link: '/settings/laundry-services',
                  icon: 'menu-icon flaticon-medal',
                  permission: 'laundry_services.list'
                },
                {
                  name: this.$t('MENU.attached_reasons'),
                  link: '/settings/attached-reasons',
                  icon: 'menu-icon flaticon-medal',
                  permission: 'attached_reasons.list'
                },
                {
                  name: this.$t('MENU.fabric_problems'),
                  link: '/settings/fabric-problems',
                  icon: 'menu-icon flaticon-medal',
                  permission: 'fabric_problems.list'
                },
                {
                  name: this.$t('MENU.laundry_services_colors'),
                  link: '/settings/laundry-services-colors',
                  icon: 'menu-icon flaticon-medal',
                  permission: 'laundry_services_colors.update'
                },
                {
                  name: this.$t('MENU.print_settings'),
                  link: '/settings/print-settings',
                  icon: 'menu-icon fa fa-print',
                  permission: 'print_setting.list'
                },
                {
                  name: this.$t('MENU.basic_status_management'),
                  link: '/settings/basic-status-management',
                  icon: 'menu-icon fa fa-print',
                  permission: 'basic_status_management.list'
                },
                {
                  name: this.$t('MENU.document_status_paths'),
                  link: '/settings/document-status-paths',
                  icon: 'menu-icon fa fa-print',
                  permission: 'document_status_paths.list'
                },
                {
                  name: this.$t('MENU.status_mapping'),
                  link: '/settings/status-mapping',
                  icon: 'menu-icon fa fa-print',
                  permission: 'status_mapping.list'
                },
                {
                  name: this.$t('MENU.connected_printers'),
                  link: '/settings/connected-printers',
                  icon: 'menu-icon fa fa-print',
                  permission: 'connected_printers.list'
                },

              ]
            },

            /**
             * taxes management
             */
            {
              name: this.$t('MENU.taxes_management'), icon: 'menu-icon flaticon-folder-1',
              active_link: ['/settings/taxes', '/settings/delimiters', '/settings/taxes-rules',],
              permission: ['taxes.list', 'delimiters.list', 'taxes_rules.list'],
              sub_links: [
                {
                  name: this.$t('MENU.taxes'),
                  link: '/settings/taxes',
                  icon: 'menu-icon flaticon-layer',
                  permission: 'taxes.list'
                },
                // {name: this.$t('MENU.delimiters'), link: '/settings/delimiters', icon: 'menu-icon flaticon2-gear', permission: 'delimiters.list'},
                // {name: this.$t('MENU.taxes_rules'), link: '/settings/taxes-rules', icon: 'menu-icon flaticon2-gear', permission: 'taxes_rules.list'},
              ]
            },

            /**
             * countries and currencies
             */
            {
              name: this.$t('MENU.countries_and_currencies'), icon: 'menu-icon flaticon-folder-1',
              active_link: ['/settings/currencies', '/settings/currency-exchange', '/settings/countries', '/settings/cash-category',],
              permission: ['currencies.list', 'currencies_convert.list', 'countries.list', 'cash_category.list'],
              sub_links: [
                {
                  name: this.$t('MENU.currencies'),
                  link: '/settings/currencies',
                  icon: 'menu-icon flaticon-coins',
                  permission: 'currencies.list'
                },
                {
                  name: this.$t('MENU.currency_exchange'),
                  link: '/settings/currency-exchange',
                  icon: 'menu-icon flaticon2-gear',
                  permission: 'currencies_convert.list'
                },
                {
                  name: this.$t('MENU.cash_categories'),
                  link: '/settings/cash-category',
                  icon: 'menu-icon flaticon-coins',
                  permission: 'cash_category.list'
                },
                {
                  name: this.$t('MENU.Countries'),
                  link: '/settings/countries',
                  icon: 'menu-icon flaticon-earth-globe',
                  permission: 'countries.list'
                },

              ]
            },

            /**
             * advanced settings
             */
            {
              name: this.$t('MENU.advanced_settings'), icon: 'menu-icon flaticon-folder-1',
              active_link: ['/settings/module-data-access', '/settings/constants', '/settings/procedures', '/settings/payments', '/settings/code-setting', '/settings/key-generation', '/settings/invoice-bond-configuration'],
              permission: ['modules_setting.list', 'constants.list', 'procedures.list', 'payments.list', 'code_setting.list', 'key_generations.list', 'invoice_bond_configuration.list', 'api_internal_configurations.list'],
              sub_links: [
                {
                  name: this.$t('MENU.module_data_access'),
                  link: '/settings/module-data-access',
                  icon: 'menu-icon flaticon2-gear',
                  permission: 'modules_setting.list'
                }, //module_data_access.list
                {
                  name: this.$t('MENU.Constants'),
                  link: '/settings/constants',
                  icon: 'menu-icon flaticon-interface-8',
                  permission: 'constants.list'
                },
                {
                  name: this.$t('MENU.procedures'),
                  link: '/settings/procedures',
                  icon: 'menu-icon flaticon-map',
                  permission: 'procedures.list'
                },
                {
                  name: this.$t('MENU.payments_management'),
                  link: '/settings/payments',
                  icon: 'menu-icon flaticon2-heart-rate-monitor',
                  permission: 'payments.list'
                },
                {
                  name: this.$t('MENU.code_setting'),
                  link: '/settings/code-setting',
                  icon: 'menu-icon flaticon2-gear',
                  permission: 'code_setting.list'
                },
                {
                  name: this.$t('MENU.key_generation'),
                  link: '/settings/key-generation',
                  icon: 'menu-icon la la-code',
                  permission: 'key_generations.list'
                },
                {
                  name: this.$t('MENU.barcode_setting'),
                  link: '/settings/barcode-setting',
                  icon: 'menu-icon fa fa-barcode',
                  permission: 'barcode_setting.update'
                },
                {
                  name: this.$t('MENU.invoice_bond_configuration'),
                  link: '/settings/invoice-bond-configuration',
                  icon: 'menu-icon la la-code',
                  permission: 'invoice_bond_configuration.list'
                },
                {
                  name: this.$t('MENU.api_internal_configurations'),
                  link: '/settings/api-internal-configurations',
                  icon: 'menu-icon la la-code',
                  permission: 'api_internal_configurations.list'
                },
              ]
            },

            /**
             * notifications and email
             */
            // {name: this.$t('MENU.notifications_and_email'), icon: 'menu-icon flaticon-folder-1',
            //   active_link: ['/settings/notifications-config', '/settings/custom-notifications', 'menu-icon flaticon2-world',],
            //   permission: ['notification_config.list', 'notification_config.create', 'smtps.update','barcode_setting.update'],
            //   sub_links: [
            //     {name: this.$t('MENU.notifications_config'), link: '/settings/notifications-config', icon: 'menu-icon flaticon2-gear', permission: 'notification_config.list'},
            //     {name: this.$t('MENU.custom_notifications'), link: '/settings/custom-notifications', icon: 'menu-icon flaticon2-gear', permission: 'notification_config.create'},
            //     {name: this.$t('MENU.smtp'), link: '/settings/smtp', icon: 'menu-icon flaticon2-world', permission: 'smtps.update'},
            //   ]},

            /**
             * template and design
             */
            // {name: this.$t('MENU.template_and_design'), icon: 'menu-icon flaticon-folder-1',
            //   active_link: ['/settings/template_design',],
            //   permission: ['template_design.update'],
            //   sub_links: [
            //     {name: this.$t('MENU.template_design'), link: '/settings/template_design', icon: 'menu-icon flaticon2-browser', permission: 'template_design.update'},
            //   ]},

            // {name: this.$t('MENU.Api_configurations'), link: '/settings/api-configurations', icon: 'menu-icon flaticon-cogwheel-1', permission: 'api-configurations.list'},
            // {name: this.$t('MENU.procedures'), link: '/settings/procedures', icon: 'menu-icon flaticon2-gear', permission: 'procedures.list'},

          ]
        }
      ];
    },
    // menuFilter(event){
    //   let _val = event.target.value;
    //   this.menu_links = [];
    //   this.getLinks().filter((row)=>{
    //     if ((row.sub_links && row.sub_links.length == 0) && row.name.includes(_val)){
    //       this.menu_links.push(row);
    //     }else if(row.sub_links && row.sub_links.length > 0){
    //       row.sub_links = row.sub_links.filter((sub_row)=>sub_row.name.includes(_val));
    //       if(row.sub_links && row.sub_links.length > 0){
    //         this.menu_links.push(row);
    //       }
    //     }
    //   });
    // }
    menuFilter(event) {
      let _val = event.target.value.toLowerCase(); // Convert to lowercase
      this.menu_links = [];
      this.getLinks().filter((row) => {
        if ((row.sub_links && row.sub_links.length == 0) && row.name.toLowerCase().includes(_val)) { // Convert to lowercase
          this.menu_links.push(row);
        } else if (row.sub_links && row.sub_links.length > 0) {
          row.sub_links = row.sub_links.filter((sub_row) => sub_row.name.toLowerCase().includes(_val)); // Convert to lowercase
          if (row.sub_links && row.sub_links.length > 0) {
            this.menu_links.push(row);
          }
        }
      });
    }

  },
  created() {
    this.menu_links = this.getLinks();
  }
};
</script>
