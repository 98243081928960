<template>
  <div
    class="subheader py-2 py-lg-4 subheader-solid"
    v-bind:class="subheaderClasses"
    id="kt_subheader"
  >
    <div
      class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="d-flex align-items-center flex-wrap mr-1">
<!--        <h5 class="text-dark font-weight-bold my-2 mr-5">-->
<!--          {{ title }}-->
<!--        </h5>-->
        <ul
          class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2"
        >
          <li class="breadcrumb-item">
            <router-link :to="'/'" class="subheader-breadcrumbs-home">
<!--              <i class="flaticon2-shelter text-muted icon-1x"></i>-->
              {{$t('MENU.DASHBOARD')}}
            </router-link>
          </li>

          <template v-for="(breadcrumb, i) in breadcrumbs">
            <li class="breadcrumb-item" :key="`${i}-${breadcrumb.id}`">
              <router-link
                v-if="breadcrumb.route"
                :key="i"
                :to="breadcrumb.route"
              >
                {{ breadcrumb.title }}
              </router-link>
              <span class="text-muted" :key="i" v-if="!breadcrumb.route">
                {{ breadcrumb.title }}
              </span>
            </li>
          </template>
        </ul>
      </div>
      <div class="d-flex align-items-center">

        <b-dropdown
          size="sm"
          variant="link"
          toggle-class="custom-v-dropdown"
          no-caret
          right
          no-flip
          text="Actions"
          v-b-tooltip.hover="$t('quick_actions')"
        >
          <template v-slot:button-content>
            <a href="javascript:;" class="btn btn-icon" data-toggle="dropdown">
              <i class="menu-icon flaticon-file-1 fs-25px text-FFB803"></i>

<!--              <span class="svg-icon svg-icon-success svg-icon-2x">-->
<!--                &lt;!&ndash;begin::Svg Icon&ndash;&gt;-->
<!--                <inline-svg src="media/svg/icons/Files/File-plus.svg" />-->
<!--                &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
<!--              </span>-->
            </a>
          </template>
          <!--begin::Navigation-->
          <div class="navi navi-hover min-w-md-250px">

            <b-dropdown-text tag="div" class="navi-item" v-for="(quick, index) in quickLinks" :key="index">
              <router-link  class="navi-link" v-if="$can(quick.permission)" :to="quick.link">
                <span class="navi-icon">
                  <i :class="quick.icon"></i>
                </span>
                <span class="navi-text">{{quick.title}}</span>
              </router-link>
            </b-dropdown-text>

          </div>
          <!--end::Navigation-->
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import javascript from "highlight.js/lib/languages/javascript";

export default {
  name: "KTSubheader",
  methods: {javascript},
  props: {
    breadcrumbs: Array,
    title: String
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    },

    subheaderClasses() {
      const classes = [];
      const style = this.layoutConfig("subheader.style");
      if (style) {
        classes.push(style);

        if (style === "solid") {
          classes.push("bg-white");
        }

        if (this.layoutConfig("subheader.fixed")) {
          classes.push("border-top");
        }
      }
      return classes.join(" ");
    },
    quickLinks(){
      return [
        {link: {name: 'items.create'}, permission: 'items.create', title: this.$t('items.new_item'), icon: 'menu-icon flaticon-open-box'},
        {link: {name: 'sales_invoices.create'}, permission: 'sales_invoices.create', title: this.$t('sales_invoices.add_sales_invoices'), icon: 'menu-icon flaticon-list-1'},
        {link: {name: 'purchases-invoices.create'}, permission: 'purchases_invoices.create', title: this.$t('purchases_invoices.add_purchases_invoice'), icon: 'menu-icon flaticon-coins'},
      ];
    }
  }
};
</script>
